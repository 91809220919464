<template>
    <div class="content">
        <!-- 头部 -->
        <header>
            <img onclick="window.history.go(-1)" src="../../assets/img/go.png" alt="">
            <span style="padding-left: 33%" class="fs">关于我们</span>
        </header>
        <!-- 第二部分 -->
        <div class="photo_img">
            <p class="fb">关于我们</p>
        </div>
        <!-- 第三部分 -->
        <div class="about">
            <div class="about_content">
                <p class="firm fz">企业介绍</p>
                <p class="introduction fs">Enterprise introduction</p>
                <p class="about_text fs">北京再佳学教育科技有限公司（以下简称再佳学）是美育融合化在线教育综合服务提供商，历时五年对小学基础教材进行二次开发，将传统艺术、现代艺术、非遗、儿童舞台剧等两百多种艺术表现形式融入小学教材，让传统的课本知识活起来，提高学生学习兴趣，同时融合人工智能和大数据分析赋能传统课本。公司核心团队由多位教育行业、微软、苹果、京东等互联网领域资深人士组成。</p>
                <p class="about_text fs">再佳学与中宣部学习强国、人民日报人民网、人民日报出版社、教育部人民教育数字出版社、中央电视台央视频、北京师范大学、人民周刊、人民数据签署合作协议。</p>
                <div class="vision">
                    <p class="vision_text fz">愿景和使命</p>
                    <p class="mission fs">Vision and Mission</p>
                    <p class="vision_title fs">愿景：促进国内教育均衡，助力中华文化走出去</p>
                    <p class="vision_title fs">使命：让每一位孩子拥有快乐的童年</p>
                    <p class="vision_title fs">价值观：快乐、尚美、融合、创新</p>
                </div>
            </div>
        </div>
        <!-- 第四部分 -->
        <div class="product">
            <div class="product_content clearfix">
                <p class="product_title fz">主推产品</p>
                <p class="main fb">main products</p>
                <p class="product_info fb">最美课本：K12美育融合化学习平台</p>
                <div class="product_text">
                    <p>
                        产品优势：
                        <span>
                            美育融合、应景学习、双语配音、人工智能、同步教材
                        </span>
                    </p>
                    <p>
                        学科分类:
                        <span>
                            语文、数学、英语
                        </span>
                    </p>
                    <p>
                        覆盖年级：
                        <span>
                            小学1-6年级
                        </span>
                    </p>
                    <p>
                        为学生带来的核心价值：
                        <span>
                            提升学习兴趣、提高阅读能力、激发脑力思维、增强知识记忆、树立人格自信
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <!-- 第五部分 -->
        <div class="growth">
            <div class="growth_content">
                <span class="growth_photo"></span>
            </div>
        </div>
        <!-- 第六部分 -->
        <div class="enterprise">
            <div class="enterprise_content">
                <p class="enterprise_title fb">企业场景</p>
                <p class="scenario fb">Enterprise scenario</p>
                <div class="enterprise_list">
                    <ul>
                        <li>
                            <img src="../../assets/img/环境.png" alt="">
                            <p class="fs">办公环境1</p>
                        </li>
                        <li>
                            <img src="../../assets/img/环境s.png" alt="">
                            <p class="fs">办公环境2</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- 关于我们 -->
        <div class="us">
            <div class="us_content clearfix">
                <p class="us_title fz">联系我们</p>
                <p class="us_text fs">About us</p>
                <div class="us_right">
                    <p class="fs">
                            公司邮箱:zaijiaxue@zjxk12.com
                            <br>
                            地址: 北京市朝阳区金台西路甲2号
                    </p>
                </div>
            </div>
        </div>

        <!-- 尾部 -->
        <footerEn></footerEn>
    </div>
</template>

<script>

export default {
    data() {
        return {
        };
    },
    mounted() {
        if (!/Android|iPhone|iPod/i.test(navigator.userAgent)) {
            this.$router.push('/about')
        } else {
            window.addEventListener('resize', this.remScale());

        }
    },
    methods: {
        remScale() {
            var winW = document.documentElement.clientWidth;
            winW = winW > 750 ? 750 : winW;
            document.documentElement.style.fontSize = winW / 750 * 100 + 'px'
        }
    }
};
</script>

<style scoped lang="scss">
    @import "../../assets/css/about_en.scss";
</style>
